<template>
   
    <b-card-actions action-collapse title="Mrt">  
        <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col md="12">
                    <b-form-group>
                    <label>Name</label>
                    <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="name"
                    >
                          <b-form-input
                                v-model="input.name"
                                placeholder="Mrt Name"
                            />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                 <b-col md="6">
                    <b-form-group>
                    <label>Lat</label>
                    <validation-provider
                    #default="{ errors }"
                    rules="min:0"
                    name="uri"
                    >
                          <b-form-input
                                v-model="input.lat"
                                placeholder="lat"
                            />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                 <b-col md="6">
                    <b-form-group>
                    <label>Lng</label>
                    <validation-provider
                    #default="{ errors }"
                    rules="min:0"
                    name="uri"
                    >
                          <b-form-input
                                v-model="input.lng"
                                placeholder="lng"
                            />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                     

                <b-col md="12">
                  <b-form-group>
                    <label>Active</label>
                    <b-form-checkbox
                        checked="true"
                        class="custom-control-primary"
                        v-model="input.is_active"
                        switch
                      />
                  </b-form-group>  
                  
                </b-col>
                
              
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>
                </b-col>
                
              </b-row>
            </b-form>
        </validation-observer>
    </b-card-actions>
      

 
</template>
<script>
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormTextarea,BFormRating,BFormCheckbox,BFormCheckboxGroup
} from 'bootstrap-vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import BCardActions from '../../../../@core/components/b-card-actions/BCardActions.vue'
import axios from '@axios'


//popup
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import router from '@/router'

export default {
  components: {
    BCardActions,
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormRating,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
        id:router.currentRoute.params.id,
        userData: JSON.parse(localStorage.getItem('userData')),
        input:{
        
          name: null,
          type_code: 'mrt',
          lat:null,
          lng:null,
          is_active:true,
         
        }
        
        
     
    }
  },
  created() {
     
     if(this.id != undefined){
        
        this.getData();
      }
   
      
  },
  methods: {
   
    async getData(){
     
      var transit = await axios.get('transit/v1/getdetail/'+this.id);
      
     if(transit){
         this.input = transit.data.data;
         this.input.is_active =  this.input.is_active===1?true:false;

     }
     
    
      
    },
    
    
    makeToast(variant = null,title,message) {
      this.$bvToast.toast(message, {
        title: title,
        variant,
        solid: true,
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
            console.log('input',this.input);
            this.input.created_by = this.userData.id;
            var data = JSON.stringify(this.input);
            if(this.id != undefined){
              axios.put('transit/v1/update/'+this.id,data)
              .then(res => { 
                console.log('res',res);
                if(!res.data.success){
                  this.makeToast('danger','Error 403',res.data.message);
                
                  
                }else{
                  this.$router.push('/mrt/list', () => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Done`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `You have successfully updated as .`,
                        },
                      })
                  });
                  

                }
              // this.actions = res.data.data; 
              }).catch(error => {
                this.makeToast('danger','Error 403',res.data.message);
                  console.log('error',error);
              })

            }else{
               axios.post('transit/v1/add',data)
              .then(res => { 
                console.log('res',res);
                if(!res.data.success){
                  this.makeToast('danger','Error 403',res.data.message);
                
                  
                }else{
                  this.$router.push('/mrt/list', () => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Done`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `You have successfully created.`,
                        },
                      })
                  });
                  

                }
            // this.actions = res.data.data; 
            }).catch(error => {
                this.makeToast('danger','Error 403',error);
                console.log('error',error);
            })

            }
          // eslint-disable-next-line
         
        }
      })
    },
    
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>